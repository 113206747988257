import { useContext } from "react";
//
import execute from "@beeldit/core/services/execute";
import { GeneratePDFContext } from "./GeneratePDFContext";

function useExecuteGenerateEventSessions() {
  const { modelId } = useContext(GeneratePDFContext);
  return (onSuccess: any) => {
    execute(`invoices/${modelId}/generate-pdf`, {})
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
      });
  };
}

export default useExecuteGenerateEventSessions;
