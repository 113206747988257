import { ObjectFieldTemplateProps } from "@rjsf/utils";

function InvoiceFormTemplate(props: ObjectFieldTemplateProps) {
  let id = null;

  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].id
  ) {
    id = props.uiSchema["ui:options"].id;
  }
  return (
    <div>
      <>
      {id == "root" ? renderForm(props) : null}
      {id == "invoice_lines" ? renderInvoiceLines(props) : null}
      {id == "receiver_account" ? renderReceiverAccount(props) : null}
      </>
    </div>
  );
}

function renderForm(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="row">
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("serial", props)?.content}
          </div>
        </div>
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("number", props)?.content}
          </div>
        </div>
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("date", props)?.content}
          </div>
        </div>        
        <div className="col-3">
          <div className="property-wrapper">
            {getPropertyByName("invoice_type_id", props)?.content}
          </div>
        </div>
        <div className="col-3">
          <div className="property-wrapper">
            {getPropertyByName("receiver_account", props)?.content}
          </div>
        </div>
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("status", props)?.content}
          </div>
        </div>
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("total_amount", props)?.content}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="property-wrapper">
          {getPropertyByName("invoice_lines", props)?.content}
        </div>
      </div>
      <div className="row">
        <div className="property-wrapper">
          {getPropertyByName("reference", props)?.content}
        </div>
      </div>
      <div className="row">
        <div className="property-wrapper">
          {getPropertyByName("notes", props)?.content}
        </div>
      </div>
    </>
  );
}

function renderReceiverAccount(props: ObjectFieldTemplateProps) {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("entity_id", props)?.content}
          </div>
        </div>
      </div>
    </div>
  )
}

function renderInvoiceLines(props: ObjectFieldTemplateProps) {
  return (
    <div className="invoice-lines mt-2">
      <div className="row">
        <div className="col-6">
          <div className="property-wrapper">
            {getPropertyByName("product", props)?.content}
          </div>
        </div>
        <div className="col-1">
          <div className="property-wrapper">
            {getPropertyByName("quantity", props)?.content}
          </div>
        </div>
        <div className="col-2">
          <div className="property-wrapper">
            {getPropertyByName("price", props)?.content}
          </div>
        </div>
        <div className="col-1">
          <div className="property-wrapper">
            {getPropertyByName("tax_percentage", props)?.content}
          </div>
        </div>
        <div className="col-1">
          <div className="property-wrapper">
            {getPropertyByName("tax_amount", props)?.content}
          </div>
        </div>
        <div className="col-1">
          <div className="property-wrapper">
            {getPropertyByName("total", props)?.content}
          </div>
        </div>
      </div>
    </div>
  );
}
function getPropertyByName(name: string, props: ObjectFieldTemplateProps) {
  return props.properties.find((element: any) => element.name == name);
}

export default InvoiceFormTemplate;
