import AuthInterceptor from '@beeldit/user-and-access/auth/interceptors/AuthInterceptor';
import axios from 'axios';

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

let api = axios.create(defaultConfig);

const getApi = () => {
  return api;
}

const addHeader = (key: string, value: any) => {
  api.defaults.headers[key] = value || '';
}

const regenerateApi = () => {
  api = axios.create(defaultConfig);
};

const addToken = (token: any) => {
  // Primero regeneramos la instancia de api
  regenerateApi();

  // Luego, configuramos el interceptor de autenticación
  const authInterceptor = AuthInterceptor(() => token);
  api.interceptors.request.use(authInterceptor);
};

export { getApi, regenerateApi, addToken, addHeader };
