import React, { createContext, useState, useContext } from "react";
import { PaginationConfig } from "@beeldit/core/models/PaginationConfig.interface";
import { TableData } from "@beeldit/core/models/TableData.interface";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

interface TenantAndCommerceContextType {
  tenantId: number | null;
  setTenantId: (tenantId: number | null) => void;
  commerceId: number | null;
  setCommerceId: (commerceId: number | null) => void;
  commerce: any;
  setCommerce: (config: any) => void;
}

export const TenantAndCommerceContext = createContext<TenantAndCommerceContextType>({
  tenantId: null,
  setTenantId: () => {},
  commerceId: null,
  setCommerceId: () => {},
  commerce: null,
  setCommerce: () => {
  },
});



interface TenantAndCommerceProviderProps {
  children: React.ReactNode;
}

export const TenantAndCommerceProvider: React.FC<TenantAndCommerceProviderProps> = ({
  children,
}) => {
  const [tenantId, setTenantId] = useState<number | null>(null);
  const [commerceId, setCommerceId] = useState<number | null>(null);
  const [commerce, setCommerce] = useState<object | null>(null);


  const value = {
    tenantId,
    setTenantId,
    commerceId,
    setCommerceId,
    commerce,
    setCommerce
  };
  return (
    <TenantAndCommerceContext.Provider value={value}>
      {children}
    </TenantAndCommerceContext.Provider>
  );
};
