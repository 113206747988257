import download from "@beeldit/core/services/download";

function useDownload() {
  return (url: string, onSuccess: any) => {
    download(`files/download`, {url})
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
      });
  };
}

export default useDownload;
