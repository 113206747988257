import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      "sign_in": "Iniciar sesión",
      "email_or_user": "Email",
      "password": "Contraseña",
      "forgot_password": "¿Olvidaste tu contraseña?",
      "invoices": "Facturas",
      "are_you_shure_you_want_to_delete_this_invoice": "¿Estás seguro que deseas eliminar esta factura?",
      "create_invoice": "Crear factura",
      "edit_invoice": "Editar factura",
      "remove_invoice": "Eliminar factura",
      "customers": "Clientes",
      "number": "Número",
      "date": "Fecha",
      "code": "Código",
      "fiscal_name": "Nombre fiscal",
      "Actions": "Acciones",
      "create_customer": "Crear cliente",
      "close": "Cerrar",
      "save_changes": "Guardar cambios",
      "edit_customer": "Editar cliente",
      "remove_customer": "Eliminar cliente",
      "are_you_shure_you_want_to_delete_this_customer": "¿Estás seguro que deseas eliminar este cliente?",
      "fiscal_first_surname": "Primer apellido fiscal",
      "fiscal_last_surname": "Segundo apellido fiscal",
      "fiscal_address": "Dirección fiscal",
      "fiscal_zip": "Código postal fiscal",
      "fiscal_city": "Ciudad fiscal",
      "fiscal_province": "Provincia fiscal",
      "fiscal_number": "Número fiscal",
      "name": "Nombre",
      "surname": "Apellido",
      "phone": "Teléfono",
      "email": "Email",
      "bank_information": "Información bancaria",
      "additonal_information": "Información adicional",
      "receiver_account": "Cliente",
      "currency": "Moneda",
      "type": "Tipo",
      "invoice_lines": "Líneas de factura",
      "product": "Producto",
      "amount": "Cantidad",
      "tax_percentage": "IVA%",
      "price": "Precio",
      "tax_amount": "T. IVA%",
      "total": "Total",
      "notes": "Notas",
      "total_amount": "Total",
      "serial": "Serie",
      "reference": "Referencia",
      "quantity": "Cantidad",
      "generate_pdf": "Generar PDF",
      "are_you_sure_you_want_to_generete_the_invoice_pdf?": "¿Estás seguro que deseas generar el PDF de esta factura?",
      "generate_facturae": "Generar Factura XML",
      "are_you_sure_you_want_to_generete_the_invoice_facturae?": "¿Estás seguro que deseas genera la factura en XML?",
      "unpaid": "Pendiente de pago",
      "paid": "Pagada",
      "rejected": "Rechazada",
      "status": "Estado",
      "amount_with_vat": "Importe Total",
      "amount_without_vat": "Base Imponible",
      "amount_vat": "Importe IVA",
      "fiscal_country_code": "País",
    }
  },  
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;