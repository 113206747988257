import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import InvoiceAccountSelector from "../../invoice-accounts/components/InvoiceAccountSelector";
import InvoiceFormTemplate from "./InvoiceFormTemplate";
import InvoiceFormArrayTemplate from "./InvoiceFormArrayTemplate";
import InvoiceTypeSelector from "../../invoice-types/components/InvoiceTypeSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
  formWidgets: any;
  invoiceConfiguration: any;
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}



function InvoiceForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const manualNumber = props.invoiceConfiguration.manual_numbers;

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    number: t("number"),
    serial: t("serial"),
    reference: t("reference"),
    date: t("date"),
    currency: t("currency"),
    type: t("type"),
    notes: t("notes"),
    issuer_account_id: t("issuer_account"),
    receiver_account_id: t("receiver_account"),
    product: t("product"),
    quantity: t("quantity"),
    tax_percentage: t("tax_percentage"),
    price: t("price"),
    tax_amount: t("tax_amount"),
    total: t("total"),
    invoice_lines: t("invoice_lines"),
    total_amount: t("total_amount"),
    status: t("status"),
    unpaid: t("unpaid"),
    paid: t("paid"),
    rejected: t("rejected"),
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      number: {
        type: "string",
        disabled: !manualNumber,
        readOnly: !manualNumber,
        title: translations.number,
      },
      serial: {
        type: "string",
        disabled: !manualNumber,
        readOnly: !manualNumber,
        title: translations.serial,
      },
      reference: {
        type: "string",
        disabled: !manualNumber,
        readOnly: !manualNumber,
        title: translations.reference,
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        default: new Date().toISOString().slice(0, 10),
      },
      receiver_account: {
        type: "object",
        title: '',
        properties: {
          entity_id: {
            type: "integer",
            title: translations.receiver_account_id,
          }
        }
      },
      currency: {
        type: "string",
        title: translations.currency,
        default: "EUR",
        oneOf: [
          {
            const: "EUR",
            title: "Euro €",
          },
          {
            const: "GBR",
            title: "Libra £",
          },
          {
            const: "HUF",
            title: "Florín Ft",
          },
          {
            const: "USD",
            title: "Dolar $",
          },
        ],
      },
      status: {
        type: "integer",
        title: translations.status,
        default: 1,
        oneOf: [
          {
            const: 1,
            title: translations.unpaid
          },
          {
            const: 2,
            title: translations.paid
          },
          {
            const: 3,
            title: translations.rejected
          },
        ],
      },
      invoice_type_id: {
        type: "integer",
        title: translations.type,
      },
      invoice_lines: {
        type: "array",
        title: translations.invoice_lines,
        items: {
          type: "object",
          properties: {
            product: {
              type: "string",
              title: translations.product,
            },
            quantity: {
              type: "number",
              title: translations.quantity,
            },
            price: {
              type: "number",
              title: translations.price,
            },
            tax_percentage: {
              type: "number",
              title: translations.tax_percentage,
              default: 21,
              oneOf: [
                {
                  const: 21,
                  title: "21%",
                },
                {
                  const: 10,
                  title: "10%",
                },
                {
                  const: 5,
                  title: "5%",
                },
                {
                  const: 4,
                  title: "4%",
                },
                {
                  const: 0,
                  title: "0%",
                },
              ],
            },
            tax_amount: {
              type: "number",
              title: translations.tax_amount,
              readOnly: true,
            },
            total: {
              type: "number",
              title: translations.total,
              readOnly: true,
            },
          },
        },
      },
      total_amount: {
        type: "number",
        title: translations.total_amount,
        readOnly: true,
      },
      notes: {
        type: "string",
        title: translations.notes,
        format: "textarea",
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:options": {
      id: "root",
    },
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    receiver_account: {
      "ui:options": {
        id: "receiver_account"
      },
      entity_id: {
        "ui:widget": "receiver-accountable-selector",
      },
    },
    invoice_type_id: {
      "ui:widget": "invoice-type-selector",
    },
    invoice_lines: {
      items: {
        "ui:options": {
          id: "invoice_lines",
        },
      },
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "receiver-accountable-selector": props.formWidgets.receiver_accountable_id },
    ...{ "invoice-account-selector": InvoiceAccountSelector },
    ...{ "invoice-type-selector": InvoiceTypeSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  const formTemplate = InvoiceFormTemplate;
  const arrayTemplate = InvoiceFormArrayTemplate;

  useEffect(() => {
    if (element.invoice_lines) {
      let total_amount = 0;
      element.invoice_lines.forEach((line: any) => {
        line.tax_amount =
          (line.price * line.quantity * line.tax_percentage) / 100;
        line.total = line.quantity * line.price + line.tax_amount;
        if (!isNaN(line.total)) {
          total_amount += line.total;
        }
      });
      element.total_amount = total_amount;
    }
  }, [element.invoice_lines]);

  return (
    <>
    <JSONSchemaForm
      noHtml5Validate
      templates={{
        ObjectFieldTemplate: formTemplate,
        ArrayFieldTemplate: arrayTemplate,
      }}
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
    </>
  );
}

export default InvoiceForm;
