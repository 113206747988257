import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import InvoiceAccountSelector from "../../invoice-accounts/components/InvoiceAccountSelector";
import InvoiceFormTemplate from "./InvoiceFormTemplate";
import InvoiceFormArrayTemplate from "./InvoiceFormArrayTemplate";
import PublicInvoiceFormTemplate from "./PublicInvoiceFormTemplate";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function PublicInvoiceForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    number: t("number"),
    serial: t("serial"),
    reference: t("reference"),
    date: t("date"),
    currency: t("currency"),
    type: t("type"),
    notes: t("notes"),
    issuer_account_id: t("issuer_account"),
    receiver_account_id: t("receiver_account"),
    product: t("product"),
    quantity: t("quantity"),
    tax_percentage: t("tax_percentage"),
    price: t("price"),
    tax_amount: t("tax_amount"),
    total: t("total"),
    invoice_lines: t("invoice_lines"),
    total_amount: t("total_amount"),
    code: t("code"),
    fiscal_name: t("fiscal_name"),
    fiscal_first_surname: t("fiscal_first_surname"),
    fiscal_last_surname: t("fiscal_last_surname"),
    fiscal_address: t("fiscal_address"),
    fiscal_zip: t("fiscal_zip"),
    fiscal_city: t("fiscal_city"),
    fiscal_province: t("fiscal_province"),
    fiscal_country_code: t("fiscal_country_code"),
    fiscal_number: t("fiscal_number"),
    phone: t("phone"),
    email: t("email"),
    additonal_information: t("additonal_information"),
    bank_information: t("bank_information"), 
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      token: {
        type: "string",
        title: "token",
        default: process.env.REACT_APP_INVOICE_SECRET,
        show: false,
      },
      number: {
        type: "string",
        title: translations.number,
      },
      serial: {
        type: "string",
        title: translations.serial,
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        default: new Date().toISOString().slice(0, 10),
      },
      issuer_account_id: {
        type: "integer",
        title: translations.issuer_account_id,
        default: 1,
        show: false,
      },
      receiver_account_id: {
        type: "integer",
        title: translations.receiver_account_id,
      },
      currency: {
        type: "string",
        title: translations.currency,
        default: "EUR",
        show: false,
        oneOf: [
          {
            const: "EUR",
            title: "Euro €",
          },
          {
            const: "GBR",
            title: "Libra £",
          },
          {
            const: "HUF",
            title: "Florín Ft",
          },
          {
            const: "USD",
            title: "Dolar $",
          },
        ],
      },
      type: {
        type: "integer",
        title: translations.type,
        default: 1,
        show: false,
        oneOf: [
          {
            const: 1,
            title: "Standard Invoice",
          },
          {
            const: 2,
            title: "Return Invoice",
          },
        ],
      },
      invoice_lines: {
        type: "array",
        title: translations.invoice_lines,
        items: {
          type: "object",
          properties: {
            product: {
              type: "string",
              title: translations.product,
            },
            quantity: {
              type: "number",
              title: translations.quantity,
            },
            price: {
              type: "number",
              title: translations.price,
            },
            tax_percentage: {
              type: "number",
              title: translations.tax_percentage,
              default: 21,
              oneOf: [
                {
                  const: 21,
                  title: "21%",
                },
                {
                  const: 10,
                  title: "10%",
                },
                {
                  const: 5,
                  title: "5%",
                },
                {
                  const: 4,
                  title: "4%",
                },
                {
                  const: 0,
                  title: "0%",
                },
              ],
            },
            tax_amount: {
              type: "number",
              title: translations.tax_amount,
              readOnly: true,
            },
            total: {
              type: "number",
              title: translations.total,
              readOnly: true,
            },
          },
        },
      },
      total_amount: {
        type: "number",
        title: translations.total_amount,
        readOnly: true,
      },
      reference: {
        type: "string",
        title: translations.reference,
        format: "textarea"
      },
      notes: {
        type: "string",
        title: translations.notes,
        format: "textarea",
      },
      accountable: {
        type: "object",
        properties: {
          accountable_class: {
            type: "string",
            default: "App\\Models\Customer",
            show: false
          },
          code: {
            type: "string",
            title: translations.code,
          },
          invoice_account: {
            type: "object",
            title: "",
            properties: {
              fiscal_name: {
                type: "string",
                title: translations.fiscal_name,
              },
              fiscal_first_surname: {
                type: "string",
                title: translations.fiscal_first_surname,
              },
              fiscal_last_surname: {
                type: "string",
                title: translations.fiscal_last_surname,
              },
              fiscal_address: {
                type: "string",
                title: translations.fiscal_address,
              },
              fiscal_zip: {
                type: "string",
                title: translations.fiscal_zip,
              },
              fiscal_city: {
                type: "string",
                title: translations.fiscal_city,
                default: "El Puerto de Santa María"
              },
              fiscal_province: {
                type: "string",
                title: translations.fiscal_province,
                default: "Cádiz"
              },
              fiscal_country_code: {
                type: "string",
                title: translations.fiscal_country_code,
                default: 'es',
                show: false,
              },
              fiscal_number: {
                type: "string",
                title: translations.fiscal_number,
              },
              phone: {
                type: "string",
                title: translations.phone,
              },
              email: {
                type: "string",
                title: translations.email,
              },          
              bank_information: {
                type: "string",
                title: translations.bank_information,
              },
              additonal_information: {
                type: "string",
                title: translations.additonal_information,
                format: "textarea",
              },
            },
          },
        }
      }
    },
  };
  const uiSchema: UiSchema = {
    "ui:options": {
      id: "root",
    },
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    receiver_account_id: {
      "ui:widget": "invoice-account-selector",
    },
    invoice_lines: {
      items: {
        "ui:options": {
          id: "invoice_lines",
        },
      }      
    },
    accountable: {
      "ui:options": {
        id: "accountable",
      },
      invoice_account: {
        "ui:options": {
          id: "invoice_account",
        },
      }
    },    
  };
  const customWidgets = {
    ...widgets,
    ...{ "invoice-account-selector": InvoiceAccountSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  const formTemplate = PublicInvoiceFormTemplate;
  const arrayTemplate = InvoiceFormArrayTemplate;

  useEffect(() => {
    if (element.invoice_lines) {
      let total_amount = 0;
      element.invoice_lines.forEach((line: any) => {
        line.tax_amount =
          (line.price * line.quantity * line.tax_percentage) / 100;
        line.total = line.quantity * line.price + line.tax_amount;
        if(!isNaN(line.total)) {        
        total_amount += line.total;
        }
      });
      element.total_amount = total_amount;
    }
  }, [element.invoice_lines]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      templates={{
        ObjectFieldTemplate: formTemplate,
        ArrayFieldTemplate: arrayTemplate,
      }}
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default PublicInvoiceForm;
