import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { t } from "i18next";
import { getApi } from "../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center" as const,
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const host = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "";
  let response = await getApi().post(`${host}/files/upload`, formData, config);
  response.data.add = true;
  return response.data;
};

function BeelditMultipleFileInput(props: any) {
  let {
    id,
    options,
    value = [],
    required,
    disabled,
    readonly,
    onChange,
  } = props;

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      try {
        const newFiles = await Promise.all(
          acceptedFiles.slice(0, props.maxFiles).map(uploadFile)
        );
        if (value === null) {
          value = [];
        }
        onChange([...value, ...newFiles]);
      } catch (error) {
        console.error("Upload failed", error);
      }
    },
    [value, onChange]
  );

  const removeFile = (fileUrl: string) => {
    onChange(value.filter((file: any) => file.url !== fileUrl));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const removeIcon = icon({ name: "circle-xmark", style: "solid" });
  return (
    <div>
      {
        addable(value) ? (
          <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{t("Drop the files here ...")}</p>
        ) : (
          <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
        )}
      </div>
        ) : null
      }
      
      <ul>
        {value
          ? value.map((file: any) => (
              <li
                className="align-items-start d-flex flex-column"
                key={file.url}
              >
                <div className="align-items-center d-flex text-nowrap text-truncate w-95">
                  <span className="text-truncate">{file.filename} </span>
                  <FontAwesomeIcon
                    icon={removeIcon}
                    className="ms-3 me-2 text-danger"
                    onClick={() => removeFile(file.url)}
                  />
                </div>
                {isImageURL(file.url) ? (
                  <div className="mt-3 w-50 mx-auto">
                    <img
                      className="img-fluid"
                      src={file.url}
                      alt={file.filename}
                    />
                  </div>
                ) : null}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

BeelditMultipleFileInput.defaultProps = {
  maxFiles: 5,
};

function addable(value: any): boolean {
  let hasId = true;
  if (value && value.length > 0) {
    hasId = value.forEach((file: any) => {
      return !file.id;
    });
  }
 return hasId;
}
function isImageURL(url: string) {
  return /^(http|https):\/\/.+\.(jpeg|jpg|png|gif)$/.test(url);
}

export default BeelditMultipleFileInput;
