import { t } from "i18next";
import Noty from "noty";

export function showSuccessMessage(message: string): void {
  new Noty({
    text: t(message) ?? "",
    theme: "relax",
    type: "success",
    layout: "topCenter",
    timeout: 6000,
  }).show();
}

export function showErrorMessage(message: string): void {
  new Noty({
    text: t(message) ?? "",
    theme: "relax",
    type: "error",
    layout: "topCenter",
    timeout: 6000,
  }).show();
}
