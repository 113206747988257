import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { MouseEventHandler, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Context {
  showModal: boolean;
  setShowModal: (showModal: boolean) => MouseEventHandler;
  loading: boolean;
}
interface ModalConfig {
  title: string;
  context: any;
  onSubmit: any;
  customShowModal?: boolean;
  customSetShowModal?: any;
  size?: string;
}

interface Props {
  modalConfig: ModalConfig;
  children: React.ReactNode;  
}

function CrudModuleFactoryModal(props: Props) {
  const { title, context, onSubmit, customShowModal, customSetShowModal, size } = props.modalConfig;
  const { children } = props;
  const { showModal, setShowModal, loading } = useContext<Context>(context);
  const handleClose = () => {
    if (customSetShowModal != undefined) {
      customSetShowModal(false);
      return;
    }
    setShowModal(false); // We pass the function to close the modal on success
  };
  const onSubmitFunction = () => {
    onSubmit();
  };
  const showModalValue = customShowModal != undefined ? customShowModal : showModal;
  const closeIcon = icon({ name: "xmark", style: "solid" })
  return (
    <>
      <Modal show={showModalValue} onHide={handleClose} className={size}>
        <Modal.Header>
          <Modal.Title as="div">{t(title)}</Modal.Title>
          <a href="javascript:;" onClick={() => {setShowModal(false)}}>
            <FontAwesomeIcon icon={closeIcon} className="ms-3 me-2 text-white"/>
          </a>          
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="outline-primary" disabled={loading} onClick={onSubmitFunction}>
          {t("save_changes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CrudModuleFactoryModal;
