const customFormats = {
    'date-time': (data: any) => {
        // Si data es nulo, entonces es válido
        if (data === null) {
          return true;
        }
        // Si no es nulo, verifica si es una fecha válida en el formato "YYYY-MM-DD"
        const isValidDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/.test(data);
        return isValidDateTime;
    },
    'date': (data: any) => {
        // Si data es nulo, entonces es válido
        if (data === null) {
          return true;
        }
        // Si no es nulo, verifica si es una fecha válida en el formato "YYYY-MM-DD"
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(data);
        return isValidDate;
    }
};
export default customFormats;