import { useContext } from "react";
//
import execute from "@beeldit/core/services/execute";
import { GenerateFacturaeContext } from "./GenerateFacturaeContext";

function useExecuteGenerateFacturae() {
  const { modelId } = useContext(GenerateFacturaeContext);
  return (onSuccess: any) => {
    execute(`invoices/${modelId}/generate-facturae`, {})
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
      });
  };
}

export default useExecuteGenerateFacturae;
