import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { useContext, useEffect } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import Sidebar from "./Sidebar";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { getApi } from "@beeldit/core/helpers/api";
import { t } from "i18next";
import useGetCommerceAction from "@main/commerces/actions/get-config/useGetCommerceAction";
import { UsersThree, Files } from "@phosphor-icons/react";

function Panel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  /** TODO - Encapsular en una logica aparte que además compruebe que estos parametros son numericos */
  const { setTenantId } = useContext(TenantAndCommerceContext);
  const { setCommerceId } = useContext(TenantAndCommerceContext);
  const { getCommerceFunction } = useGetCommerceAction();

  const { tenantId, commerceId } = useParams();
  
  const tenant = user?.extra_data?.tenant.id;
  const commerce = user?.extra_data?.tenant?.commerce.id;
  
  if (tenantId) {
    setTenantId(parseInt(tenantId));
    getApi().defaults.headers.common["tenantId"] = tenantId;
  }

  if (commerceId) {
    setCommerceId(parseInt(commerceId));
    getApi().defaults.headers.common["commerceId"] = commerceId;
  }

  useEffect(() => {
    if(!commerceId) return;
    getCommerceFunction(parseInt(commerceId));
  }, [commerceId]);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }



  const items: any[] = [
    {
      label: t("customers"),
      to: `/panel/${tenant}/${commerce}/customers`,
      icon: <UsersThree size={24} />,
    },
    {
      label: t("invoices"),
      to: `/panel/${tenant}/${commerce}/invoices`,
      icon: <Files size={24} />,
    },
  ];

  return (
    <div>
      <AppNavbar items={items} />
      <div className="container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
