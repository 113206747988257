import { useContext } from "react";
import { GenerateEventSessionsModel } from "./GeneratePDFModel";
import { GeneratePDFContext } from "./GeneratePDFContext";

function useInitializeGenerateEventSessions() {
  const { setElement, setShowModal, setModelId } = useContext(
    GeneratePDFContext
  );
  return (id: number) => {
    setModelId(id);
    setElement(new GenerateEventSessionsModel());
    setShowModal(true);
  };
}

export default useInitializeGenerateEventSessions;
