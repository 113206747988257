import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import useInitialize from "./useInitialize";
import useStore from "./useStore";
import InvoiceForm from "../../components/InvoiceForm";
import { PublicCreateActionContext } from "./PublicCreateActionContext";
import PublicInvoiceForm from "../../components/PublicInvoiceForm";

function usePublicCreateAction(): any {
  const initialize = useInitialize();
  const initializeFunction = () => initialize();

  /**
   * Form store success function definition
   */
  const onSuccessStore = () => {
    initialize(); // We pass the function to refresh the table on success
  };

  const store = useStore();
  const storeFunction = () => store(onSuccessStore);


  /** Creation and edition modal element definition */
  const { element } = useContext(PublicCreateActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      storeFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: PublicCreateActionContext,
    className: "invoice-form",
  };

  const actionTemplate = (
      element ? <PublicInvoiceForm formConfig={formConfig} /> : null
  );

  const onSubmitFunction = () => {
    formRef.current?.formElement.current.requestSubmit();
  }

  /** END EDIT FORM ACTION */

  return {
    initializeFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default usePublicCreateAction;
