import { getApi } from "@beeldit/core/helpers/api";

function login(loginData: any, onSuccess: any, onError: any) {
  const login = async (loginData: any) => {
    try {
      const response = await getApi().post("/login", loginData);
      onSuccess(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      onError(error);
    }
  };

  login(loginData);
}

export default login;
