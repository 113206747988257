import useDownload from "./useDownload";

function useDownloadXMLAction(): any {

  const onSuccess = (data: any) => {    
    const blob = new Blob([data], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'factura.xsig';
    link.click();    
  };

  const download = useDownload();
  const downloadXMLFunction = (url: string) => download(url, onSuccess);
  
  return {
    downloadXMLFunction,    
  };
}

export default useDownloadXMLAction;
