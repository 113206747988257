import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import CountrySelector from "@main/modules/bloonde/location/countries/components/CountrySelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function CustomerForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    code: t("code"),
    fiscal_name: t("fiscal_name"),
    fiscal_first_surname: t("fiscal_first_surname"),
    fiscal_last_surname: t("fiscal_last_surname"),
    fiscal_address: t("fiscal_address"),
    fiscal_zip: t("fiscal_zip"),
    fiscal_city: t("fiscal_city"),
    fiscal_province: t("fiscal_province"),
    fiscal_country_code: t("fiscal_country_code"),
    fiscal_number: t("fiscal_number"),
    phone: t("phone"),
    email: t("email"),
    additonal_information: t("additonal_information"),
    bank_information: t("bank_information"), 
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      code: {
        type: "string",
        title: translations.code,
      },
      invoice_account: {
        type: "object",
        title: "",
        properties: {
          fiscal_name: {
            type: "string",
            title: translations.fiscal_name,
          },
          fiscal_first_surname: {
            type: "string",
            title: translations.fiscal_first_surname,
          },
          fiscal_last_surname: {
            type: "string",
            title: translations.fiscal_last_surname,
          },
          fiscal_address: {
            type: "string",
            title: translations.fiscal_address,
          },
          fiscal_zip: {
            type: "string",
            title: translations.fiscal_zip,
          },
          fiscal_city: {
            type: "string",
            title: translations.fiscal_city,
          },
          fiscal_province: {
            type: "string",
            title: translations.fiscal_province,
          },
          fiscal_country_code: {
            type: "string",
            title: translations.fiscal_country_code,
            default: "ES",
          },
          fiscal_number: {
            type: "string",
            title: translations.fiscal_number,
          },
          phone: {
            type: "string",
            title: translations.phone,
          },
          email: {
            type: "string",
            title: translations.email,
          },          
          bank_information: {
            type: "string",
            title: translations.bank_information,
          },
          additonal_information: {
            type: "string",
            title: translations.additonal_information,
            format: "textarea",
          },
        },
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    invoice_account:{
      "ui:options": {
        id: "invoice_account"
      },
      fiscal_country_code: {
        "ui:widget": "country-selector",
      },
    }
  };

  const customWidgets = {
    ...widgets,
    ...{ "country-selector": CountrySelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default CustomerForm;
