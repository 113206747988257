import { useContext } from "react";
import { PublicCreateActionContext } from "./PublicCreateActionContext";
import { Invoice } from "../../models/Invoice";
import { InvoiceLine } from "../../models/InvoiceLine";

function useInitialize() {
  const { setElement } = useContext(PublicCreateActionContext);
  return () => {
    let element = {
      invoice_lines: [new InvoiceLine()]
    }
    setElement(element);
  };
}

export default useInitialize;
