import { useNavigate } from "react-router-dom";
import { showErrorMessage } from "../helpers/messagesHelper";

function useErrorHandler() {
  const navigate = useNavigate();
  return (error: any, setBackendFormErrors: any = null) => {
    if (error.response.status === 401) {
      let message = error.response.data.error ? error.response.data.error : error.response.data.message;
      showErrorMessage(message);
      if (message === "Unauthenticated.") {
        navigate("/login");
      }
    } else if (error.response.status === 422) {
      let formattedError = formatErrors(error.response.data.errors);
      setBackendFormErrors(formattedError);
    } else {
      let message = error.response.data.error ? error.response.data.error : error.response.data.message;
      showErrorMessage(message);
    }
  };
}

function formatErrors(errors: any) {
  let formattedErrors: any = {};
  for (let key in errors) {
    // si key no contiene un punto, es un error del formulario
    if (!key.includes(".")) {
      formattedErrors[key] = { __errors: errors[key] };
      continue;
    } else {
      // Tenemos que de forma dinamica sacar las keys de key separando por punto y setear el objeto de formattedErrores con las keys sacadas y en la ultima key guardar el error
      let keys = key.split(".");
      let lastKey = keys.pop();
      let currentObject = formattedErrors;
      keys.forEach((key) => {
        if (!currentObject[key]) {
          currentObject[key] = {};
        }
        currentObject = currentObject[key];
      });
      if (lastKey) {
        currentObject[lastKey] = { __errors: errors[key] };
      }
    }
  }
  return formattedErrors;
}

export default useErrorHandler;
