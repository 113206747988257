import { useContext } from "react";
import { CreateActionContext } from "./CreateActionContext";

function useInitialize() {
  const { setElement, setShowModal } = useContext(CreateActionContext);
  return () => {
    setElement({});
    setShowModal(true);
  };
}

export default useInitialize;
