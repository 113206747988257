import { useContext } from "react";
import { GenerateFacturaeModel } from "./GenerateFacturaeModel";
import { GenerateFacturaeContext } from "./GenerateFacturaeContext";

function useInitializeGenerateFacturae() {
  const { setElement, setShowModal, setModelId } = useContext(
    GenerateFacturaeContext
  );
  return (id: number) => {
    setModelId(id);
    setElement(new GenerateFacturaeModel());
    setShowModal(true);
  };
}

export default useInitializeGenerateFacturae;
