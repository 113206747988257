import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface Context {
    setFiltersLoaded: (tableData: any) => MouseEventHandler, 
    setTableFilters: (tableData: any) => MouseEventHandler
}


function useFilters(filters: any, contextClass: any) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const {setFiltersLoaded} = useContext<Context>(contextClass);   
    const {setTableFilters} = useContext<Context>(contextClass);   
    
    useEffect(() => {
    
        for (const [key, value] of queryParams.entries()) {
          if (filters[key] !== undefined) {
            const parsedValue = parseInt(value);
            if(!isNaN(parsedValue)) {
              filters[key] = parsedValue;
            } else {
              filters[key] = value;
            }            
          }
        }
    
        setTableFilters(filters);
        setFiltersLoaded(true);
    }, []);
}

export default useFilters;