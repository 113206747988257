import moment from "moment";
import React, { useContext } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import checkPermissions from "@beeldit/user-and-access/auth/services/checkPermissions";
import checkRoles from "@beeldit/user-and-access/auth/services/checkRoles";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { FinalTableConfig } from "../models/FinalTableConfig.interface";
import { User } from "@beeldit/user-and-access/users/models/User";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";
import { t } from "i18next";

import "../scss/BeelditTable.css";

interface Props {
  config: FinalTableConfig;
  data: {
    elements: any[];
    totalElements: number;
  };
  orderBy?: any;
  onOrderByChange?: any;
}

function BeelditTable(props: Props) {
  const { columns, rowActions, actions, rowConfiguration } = props.config;
  const { elements } = props.data;
  const { orderBy, onOrderByChange } = props;

  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const up = icon({ name: "sort-up", style: "solid" });
  const down = icon({ name: "sort-down", style: "solid" });
  const sort = icon({ name: "sort", style: "solid" });

  return (
    <div>
      <Table hover responsive>
        <thead>
          <tr>
            {user ? (
              <>
                {columns.map((column) => {
                  if (
                    (!column.permissions ||
                      checkPermissions(user, column.permissions)) &&
                    (!column.roles || checkRoles(user, column.roles))
                  ) {
                    let className = "text-uppercase " + column.type;
                    return (
                      <th className={className} key={column.key}>
                        {column.sortable ? (
                          <>
                            {orderBy === "-" + column.name ? (
                              <FontAwesomeIcon
                                icon={down}
                                className="me-2 click"
                                onClick={() => {
                                  onOrderBy(
                                    column.name,
                                    orderBy,
                                    onOrderByChange
                                  );
                                }}
                              />
                            ) : null}
                            {orderBy === "+" + column.name ? (
                              <FontAwesomeIcon
                                icon={up}
                                className="me-2 click"
                                onClick={() => {
                                  onOrderBy(
                                    column.name,
                                    orderBy,
                                    onOrderByChange
                                  );
                                }}
                              />
                            ) : null}
                            {orderBy !== "+" + column.name &&
                            orderBy !== "-" + column.name ? (
                              <FontAwesomeIcon
                                icon={sort}
                                className="me-2 click"
                                onClick={() => {
                                  onOrderBy(
                                    column.name,
                                    orderBy,
                                    onOrderByChange
                                  );
                                }}
                              />
                            ) : null}
                          </>
                        ) : null}
                        {t(column.label)}
                      </th>
                    );
                  }
                  return null;
                })}
              </>
            ) : null}
            <th className="action-column text-uppercase">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {renderElements(
            elements,
            columns,
            rowActions,
            rowConfiguration,
            user
          )}
        </tbody>
      </Table>
    </div>
  );
}

function onOrderBy(label: string, orderBy: string, onOrderByChange: any) {
  let newOrderBy;
  if (orderBy) {
    const direction = orderBy.substring(0, 1);
    const labelToOrder = orderBy.substring(1);
    if (label === labelToOrder) {
      newOrderBy = direction === "+" ? "-" + label : "+" + label;
    } else {
      newOrderBy = "+" + label;
    }
  } else {
    newOrderBy = "+" + label;
  }
  onOrderByChange(newOrderBy);
}

function renderElements(
  elements: any[],
  columns: any[],
  rowActions: any[],
  rowConfiguration: any,
  user: User | null
): any {
  return elements.map((element) => {
    return (
      <tr className={rowConfiguration.class(element)} key={element.id}>
        {renderElement(element, columns, rowActions, user)}
      </tr>
    );
  });
}

function renderElement(
  element: any,
  columns: any[],
  rowActions: any[],
  user: User | null
): any {
  return columns
    .map((column) => {
      if (
        (!column.permissions || checkPermissions(user, column.permissions)) &&
        (!column.roles || checkRoles(user, column.roles))
      ) {
        let className = "ps-2 " + column.type;
        return (
          <td key={column.key} className={className}>
            {getFieldValue(element, column.key, column)}
          </td>
        );
      }
      return null;
    })
    .concat(renderRowActions(rowActions, element, user));
}

function renderRowActions(
  rowActions: any[],
  element: any,
  user: User | null
): any {
  return (
    <td key="action" className="actions-buttons text-right">
      {rowActions.map((rowAction) => {
        if (
          checkPermissions(user, rowAction.permissions) &&
          checkRoles(user, rowAction.roles) &&
          checkShow(rowAction.show, element)
        ) {
          return (
            // Si rowAction no tiene actions se muestra el botón
            !rowAction.actions ? (
              <Button
                className="icon-button mx-1 rounded-circle button-action btn-action btn btn-primary"
                key={rowAction.label}
                onClick={() =>
                  rowAction.action(getActionData(rowAction, element))
                }
              >
                {rowAction.icon}
              </Button>
            ) : (
              // Si rowActions tiene actions mostramos el botón con el icon y el dropdown. En el dropdown se muestran los actions
              <Dropdown key={rowAction.label}>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="icon-button"
                >
                  <FontAwesomeIcon icon={rowAction.icon} className="me-2" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {rowAction.actions.map((action: any) => {
                    return (
                      <Dropdown.Item
                        key={action.label}
                        onClick={() =>
                          action.action(getActionData(action, element))
                        }
                      >
                        <FontAwesomeIcon icon={action.icon} className="me-2" />
                        {t(action.label)}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )
          );
        }
        return null;
      })}
    </td>
  );
}

function getFieldValue(element: any, key: string, column: any): any {
  const keys = key.split(".");

  const faCheck = icon({ name: "check", style: "solid" });
  const faXmark = icon({ name: "xmark", style: "solid" });

  for (let key of keys) {
    if (element[key] === undefined || element[key] == null) {
      return null;
    }
    element = element[key];
  }
  if (column.type === "boolean") {
    return element ? (
      <FontAwesomeIcon icon={faCheck} />
    ) : (
      <FontAwesomeIcon icon={faXmark} />
    );
  }

  if (column.type === "url") {
    if (element.includes("http")) {
      return (
        <a target="_blank" rel="noreferrer" href={element}>
          {element}
        </a>
      );
    }
  }

  if (column.type === "currency") {
    if (column.currency === "EUR") {
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      }).format(element);
    }
  }

  element = format(element, column);
  return element;
}

function format(value: any, column: any): any {
  if (column.type == "date") {
    value = moment(value, "YYYY-MM-DD");
    return value.format("DD/MM/YYYY");
  } else if (column.type == "datetime") {
    value = moment(value);
    return value.format("DD/MM/YYYY HH:mm");
  } else if (column.type == "translation") {
    const prefix = column.translation_prefix;
    value = t(prefix + "_" + value);
    return value;
  } else if (column.type == "percentage") {
    value = value + "%";
    return value;
  }
  return value;
}

function getActionData(action: any, element: any): any {
  let data = action.data == undefined ? "id" : action.data;
  
  if (data) {
    data = data.split(".");

    for (let key of data) {
      if (element[key] === undefined || !element[key]) {
        return null;
      }

      element = element[key];
    }
  }

  return element;
}

function checkShow(show: any, element: any): boolean {
  if (typeof show === "boolean") {
    return show;
  } else if (typeof show === "function") {
    return show(element);
  }
  return false;
}

export default BeelditTable;
