import { useContext } from "react";
import { RemoveActionContext } from "./RemoveActionContext";

function usePrepareRemove() {
  const {setElementId, setShowModal} = useContext(RemoveActionContext);
  return (id: any) => {
    setElementId(id);        
    setShowModal(true)
  }
}

export default usePrepareRemove;