import useInitialize from "./useInitialize";
import useExecute from "./useExecute";
import { useContext } from "react";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import { t } from "i18next";
import { GenerateFacturaeContext } from "./GenerateFacturaeContext";

function useGenerateFacturaeAction(index: any): any {
  const initialize = useInitialize();
  const initializeFunction = (id: number) => initialize(id);

  const onSuccessExecute = () => {
    setShowModal(false);
    index();
  };

  const execute = useExecute();

  const executeFunction = () => execute(onSuccessExecute);

  const { setShowModal } = useContext(GenerateFacturaeContext);

  const modalConfig = {
    context: GenerateFacturaeContext,
    title: t("generate_facturae"),
    onSubmit: () => executeFunction(),
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      {t("are_you_sure_you_want_to_generete_the_invoice_facturae?")}
    </CrudModuleFactoryModal>
  );

  /** END EDIT TABLE ACTION */

  return {
    initializeFunction,
    actionTemplate,
  };
}

export default useGenerateFacturaeAction;
