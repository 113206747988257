import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGetConfig from "./useGetConfig";

function useGetCommerceAction(): any {
  const get = useGetConfig();
  const getCommerceFunction = (id: number) => get(id);

  return {
    getCommerceFunction
  };
}

export default useGetCommerceAction;
