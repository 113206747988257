import { Pagination } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { PaginationConfig } from "../models/PaginationConfig.interface";

interface Prop {
  paginationConfig: PaginationConfig;
  totalElements: number | undefined;
  onPaginationChange: any;
}

function BeelditTablePagination(props: Prop) {
  const { paginationConfig, totalElements, onPaginationChange } = props;
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    let pages = calculatePages(
      paginationConfig,
      totalElements ? totalElements : 0
    );
    setPages(pages);
  }, [paginationConfig, totalElements]);

  return (
    <div className="float-end">
      <Pagination>
        <Pagination.First onClick={() => onPaginationChange(1)} />
        <Pagination.Prev
          onClick={() =>
            onPaginationChange(calculatePreviousPage(paginationConfig))
          }
        />
        {pages.map((page: any) => {
          return (
            <Fragment key={page.key}>
              <Pagination.Item
                active={page.active}
                onClick={() => onPaginationChange(page.number)}
              >
                {page.number}
              </Pagination.Item>
            </Fragment>
          );
        })}
        <Pagination.Next
          onClick={() =>
            onPaginationChange(
              calculateNextPage(
                paginationConfig,
                totalElements ? totalElements : 0
              )
            )
          }
        />
        <Pagination.Last
          onClick={() =>
            onPaginationChange(
              calculateLastPage(
                paginationConfig,
                totalElements ? totalElements : 0
              )
            )
          }
        />
      </Pagination>
      <br />
    </div>
  );
}

function calculatePages(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  let active = paginationConfig.page;
  let pages: any[] = [];
  let lastPage = calculateLastPage(paginationConfig, totalElements);
  for (let number = 1; number <= lastPage; number++) {
    pages.push({
      key: number,
      active: number === active,
      number: number,
    });
  }
  return pages;
}
function calculatePreviousPage(paginationConfig: PaginationConfig) {
  return Math.max(paginationConfig.page - 1, 1);
}
function calculateNextPage(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  return Math.min(
    paginationConfig.page + 1,
    calculateLastPage(paginationConfig, totalElements)
  );
}
function calculateLastPage(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  return Math.ceil(totalElements / paginationConfig.elementsPerPage);
}

export default BeelditTablePagination;
