import { User } from "../../users/models/User";

function checkPermissions(user: User | null, permissions: string[]): boolean {
  if(!user) {
    return false;
  }
  if (permissions === undefined) {
    return true;
  }
  return permissions.some((permission) => user.permissions.includes(permission));
}

export default checkPermissions;