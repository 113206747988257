import { ObjectFieldTemplateProps } from "@rjsf/utils";

function PublicInvoiceFormTemplate(props: ObjectFieldTemplateProps) {
  let id = null;

  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].id
  ) {
    id = props.uiSchema["ui:options"].id;
  }
  return (
    <div>
      <>
      {id == "root" ? renderForm(props) : null}
      {id == "invoice_lines" ? renderInvoiceLines(props) : null}
      {id == "accountable" ? renderAccountable(props) : null}
      {id == "invoice_account" ? renderInvoiceAccount(props) : null}
      </>
    </div>
  );
}

function renderForm(props: ObjectFieldTemplateProps) {
  return (
    <>
      <h4 className="my-3">Datos principales</h4>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("serial", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("number", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("date", props)?.content}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("accountable", props)?.content}
          </div>
        </div>
      </div>

      <h4 className="my-3">Líneas de pedido</h4>
      <div className="row justify-content-end">
        <div className="property-wrapper">
          {getPropertyByName("invoice_lines", props)?.content}
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("total_amount", props)?.content}
          </div>
        </div>
      </div>
      <h4 className="my-3">Otros</h4>
      <div className="row">
        <div className="property-wrapper">
          {getPropertyByName("reference", props)?.content}
        </div>
      </div>
      <div className="row">
        <div className="property-wrapper">
          {getPropertyByName("notes", props)?.content}
        </div>
      </div>
    </>
  );
}

function renderAccountable(props: ObjectFieldTemplateProps) {
  return (
    <>
      <h4 className="my-3">Datos del cliente</h4>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("code", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("invoice_account", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}
function renderInvoiceAccount(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_name", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_first_surname", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_last_surname", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_address", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_zip", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_city", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_province", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("fiscal_number", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("phone", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("email", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

function renderInvoiceLines(props: ObjectFieldTemplateProps) {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("product", props)?.content}
          </div>
        </div>
        <div className="col-6">
          <div className="property-wrapper">
            {getPropertyByName("quantity", props)?.content}
          </div>
        </div>
        <div className="col-6">
          <div className="property-wrapper">
            {getPropertyByName("price", props)?.content}
          </div>
        </div>
        <div className="col-6">
          <div className="property-wrapper">
            {getPropertyByName("tax_percentage", props)?.content}
          </div>
        </div>
        <div className="col-6">
          <div className="property-wrapper">
            {getPropertyByName("tax_amount", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("total", props)?.content}
          </div>
        </div>
      </div>
    </div>
  );
}
function getPropertyByName(name: string, props: ObjectFieldTemplateProps) {
  return props.properties.find((element: any) => element.name == name);
}

export default PublicInvoiceFormTemplate;
