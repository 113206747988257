import { useContext, useEffect } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import usePublicCreateAction from "../actions/public-create/usePublicCreateAction";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { useParams } from "react-router-dom";
import { getApi } from "@beeldit/core/helpers/api";

function InvoiceCreation() {

  /** TODO - Encapsular en una logica aparte que además compruebe que estos parametros son numericos */
  const { setTenantId } = useContext(TenantAndCommerceContext);
  const { setCommerceId } = useContext(TenantAndCommerceContext);

  const { tenantId, commerceId } = useParams();

  if (tenantId) {
    setTenantId(parseInt(tenantId));
    getApi().defaults.headers.common["tenantId"] = tenantId;
  }

  if (commerceId) {
    setCommerceId(parseInt(commerceId));
    getApi().defaults.headers.common["commerceId"] = commerceId;
  }

  const { initializeFunction, onSubmitFunction, actionTemplate } =
    usePublicCreateAction();

  useEffect(() => {
    initializeFunction();
  }, []);

  return (
    <>
      <div className="p-4">
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between ps-3 text-bg-primary">
            <Card.Title>{t("create_invoice")}</Card.Title>
          </Card.Header>
          <Card.Body>
            {actionTemplate}
            <div className="text-end">
              <Button variant="outline-primary" onClick={onSubmitFunction}>
                {t("save_changes")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default InvoiceCreation;
