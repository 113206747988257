import React, { createContext, useState, useContext } from "react";

interface RemoveActionContextType {
  elementId: any;
  setElementId: (elementId: any) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;
}

export const RemoveActionContext = createContext<RemoveActionContextType>({
  elementId: null,
  setElementId: () => {},
  showModal: false,
  setShowModal: () => {},
  backendFormErrors: {},
  setBackendFormErrors: () => {},
});

interface RemoveActionProviderProps {
  children: React.ReactNode;
}

export const RemoveActionProvider: React.FC<RemoveActionProviderProps> = ({
  children,
}) => {
  const [elementId, setElementId] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [backendFormErrors, setBackendFormErrors] = useState(null);

  const value = {
    elementId,
    setElementId,
    showModal,
    setShowModal,    
    backendFormErrors,
    setBackendFormErrors,
  };
  return (
    <RemoveActionContext.Provider value={value}>
      {children}
    </RemoveActionContext.Provider>
  );
};
