import useDownload from "./useDownload";

function useDownloadPDFAction(): any {

  const onSuccess = (data: any) => {    
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'factura.pdf';
    link.click();    
  };

  const download = useDownload();
  const downloadPDFFunction = (url: string) => download(url, onSuccess);
  
  return {
    downloadPDFFunction,    
  };
}

export default useDownloadPDFAction;
