export class InvoiceLine {
    public id: number | null;
    public invoice_id: number | null;
    public product: string;
    public quantity: number;
    public price: number;
    public tax_percentage: number;
    public tax_amount: number;
    public total: number;
    public created_at: string;
    public updated_at: string;
    public deleted_at: string;

    constructor() {
        this.id = null;
        this.invoice_id = null;
        this.product = '';
        this.quantity = 1;
        this.price = 0;
        this.tax_percentage = 21;
        this.tax_amount = 0;
        this.total = 0;      
        this.created_at = '';
        this.updated_at = '';
        this.deleted_at = '';
    }
}