import { useContext, useEffect} from 'react';
import { FinalTableConfig } from '@beeldit/core/models/FinalTableConfig.interface';
import { ListActionContext } from '../actions/list/ListActionContext';
import BeelditTable from '@beeldit/core/components/BeelditTable';

interface Props {
    tableConfig: FinalTableConfig,
    tableData: any,
    onOrderByChange: any
}

function InvoiceTable(props: Props) {

    const { tableData, tableConfig, onOrderByChange } = props;
    const { orderBy } = useContext(ListActionContext);

    useEffect(() => {
        tableConfig.index();
    }, []);    

    return (
        tableData ? (<BeelditTable config={tableConfig} data={tableData} orderBy={orderBy} onOrderByChange={onOrderByChange}></BeelditTable>) : (<div>Loading...</div>)
    )
}

export default InvoiceTable;
