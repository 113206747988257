import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useParams,
} from "react-router-dom";
import Panel from "@main/panel-components/Panel";
import Login from "@beeldit/user-and-access/auth/pages/Login";
import { AuthProvider } from "@beeldit/user-and-access/auth/AuthContext";
import InvoicesPage from "./modules/bloonde/billing/invoices/pages/InvoicesPage";
import { CreateActionProvider as InvoiceCreateActionProvider } from "./modules/bloonde/billing/invoices/actions/create/CreateActionContext";
import { ListActionProvider as InvoiceListActionProvider } from "./modules/bloonde/billing/invoices/actions/list/ListActionContext";
import { RemoveActionProvider as InvoiceRemoveActionProvider } from "./modules/bloonde/billing/invoices/actions/remove/RemoveActionContext";
import { EditActionProvider as InvoiceEditActionProvider } from "./modules/bloonde/billing/invoices/actions/edit/EditActionContext";
import { GeneratePDFProvider as InvoiceGeneratePDFActionProvider } from "./modules/bloonde/billing/invoices/actions/generate-pdf/GeneratePDFContext";
import { PublicCreateActionProvider as InvoicePublicCreateActionProvider } from "./modules/bloonde/billing/invoices/actions/public-create/PublicCreateActionContext";
import { GenerateFacturaeProvider as InvoiceGenerateFacturaeActionProvider } from "./modules/bloonde/billing/invoices/actions/generate-facturae/GenerateFacturaeContext";

import CustomersPage from "./customers/pages/CustomersPage";
import { CreateActionProvider as CustomerCreateActionProvider } from "./customers/actions/create/CreateActionContext";
import { ListActionProvider as CustomerListActionProvider } from "./customers/actions/list/ListActionContext";
import { RemoveActionProvider as CustomerRemoveActionProvider } from "./customers/actions/remove/RemoveActionContext";
import { EditActionProvider as CustomerEditActionProvider } from "./customers/actions/edit/EditActionContext";
import InvoiceCreation from "./modules/bloonde/billing/invoices/pages/InvoiceCreation";
import { TenantAndCommerceContext, TenantAndCommerceProvider } from "./contexts/TenantAndCommerceContext";
import CustomerSelector from "./customers/components/CustomerSelector";
import { useContext } from "react";

const formWidgets = { receiver_accountable_id: CustomerSelector };


const InvoicesPageWrapper = () => {
  const { commerce } = useContext(TenantAndCommerceContext);
  const invoiceConfiguration = commerce? commerce.invoice_configuration : {};
  const formDefaultValues =  {
    receiver_account: {
      entity_type: 'App\\Models\\Customer'
    }
  };

  return <InvoicesPage formDefaultValues={formDefaultValues} formWidgets={formWidgets} invoiceConfiguration={invoiceConfiguration} />;
};
  
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <div className="App">
          <Outlet />
        </div>
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        index: true,
        element: <Navigate to="/panel/1/2/invoices" />,
      },
      {
        path: ":tenantId/:commerceId/create-invoice",
        element: (
          <InvoicePublicCreateActionProvider>
            <InvoiceCreation/>
          </InvoicePublicCreateActionProvider>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/panel",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          {
            path: ":tenantId/:commerceId/customers",
            element: (
              <CustomerCreateActionProvider>
                <CustomerListActionProvider>
                  <CustomerRemoveActionProvider>
                    <CustomerEditActionProvider>
                      <CustomersPage />
                    </CustomerEditActionProvider>
                  </CustomerRemoveActionProvider>
                </CustomerListActionProvider>
              </CustomerCreateActionProvider>
            ),
          },
          {
            path: ":tenantId/:commerceId/invoices",
            element: (
              <InvoiceGeneratePDFActionProvider>
                <InvoiceGenerateFacturaeActionProvider>
                  <InvoiceCreateActionProvider>
                    <InvoiceListActionProvider>
                      <InvoiceRemoveActionProvider>
                        <InvoiceEditActionProvider>
                        <InvoicesPageWrapper />
                          {/* <InvoicesPage
                            formDefaultValues={ {issuer_accountable_id: ":commerceId", issuer_accountable_type: 'App\\Models\\Commerce', receiver_accountable_type: 'App\\Models\\Customer'} }
                            formWidgets={formWidgets}
                          /> */}
                        </InvoiceEditActionProvider>
                      </InvoiceRemoveActionProvider>
                    </InvoiceListActionProvider>
                  </InvoiceCreateActionProvider>
                </InvoiceGenerateFacturaeActionProvider>
              </InvoiceGeneratePDFActionProvider>
            ),
          },
        ],
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
