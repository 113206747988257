import { useNavigate } from 'react-router-dom';

function AddQueryParamsToURL() {
  const navigate = useNavigate();

  const addQueryParamsToURL = (filters: any) => {
    for (const [key, value] of Object.entries(filters)) {
      const searchParams = new URLSearchParams(window.location.search);
      if(value) {        
        searchParams.set(key as string, value as string);        
      } else {
        searchParams.delete(key as string);
      }
      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  return addQueryParamsToURL;
}

export default AddQueryParamsToURL;