function useProcessSchema() {
    
    return (schema: any, element: any) => {
        let processedSchema = {...schema}; // Duplicamos el esquema para no modificar el original
        Object.keys(processedSchema.properties).forEach((key) => {
            // Revisamos si el campo tiene la propiedad 'show'
            if (processedSchema.properties[key].hasOwnProperty('show')) {
                // Si 'show' es falso, eliminamos la propiedad del esquema
                if (!processedSchema.properties[key].show) {
                    element[key] = processedSchema.properties[key].default;
                    delete processedSchema.properties[key];
                    
                }
                // Si no, eliminamos la propiedad 'show' del esquema, ya que no es una propiedad válida para JSON Schema
                else {
                    delete processedSchema.properties[key].show;
                }
            }
        });
        return processedSchema;
    }

}
export default useProcessSchema;