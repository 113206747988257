const filterConfig = {
  type: 'object',
  properties: {
        // customer_id: { 
        //     type: 'integer', 
        // },
        // report_id: { 
        //     type: 'integer', 
        // },
        search: {
            type: 'string',
        },
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;